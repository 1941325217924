// src/pages/Features.js
import React from "react";
import { motion } from "framer-motion";
import {
  FaCog,
  FaIndustry,
  FaLightbulb,
  FaUserShield,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaRobot,
  FaChartLine,
  FaDatabase,
  FaShieldAlt,
  FaBolt,
  FaGlobe,
} from "react-icons/fa";
import { Parallax } from "react-scroll-parallax";

const features = [
  {
    icon: <FaRobot size={60} />,
    title: "AI-Powered Scraping",
    description:
      "Our advanced AI algorithms can extract data from any website, handling complex structures and dynamic content effortlessly.",
    detail:
      "With AI-based learning, the tool adapts to changes in website structure automatically, ensuring data accuracy without the need for manual adjustments.",
  },
  {
    icon: <FaChartLine size={60} />,
    title: "Real-Time Data Analysis",
    description:
      "Transform scraped data into actionable insights with real-time analysis and visualizations.",
    detail:
      "Generate charts, graphs, and reports instantly from the extracted data. This feature helps you make data-driven decisions faster than ever.",
  },
  {
    icon: <FaDatabase size={60} />,
    title: "Scalable Infrastructure",
    description:
      "Handle millions of web pages with our scalable scraping solution, perfect for businesses of any size.",
    detail:
      "Our infrastructure scales seamlessly with your needs, from small-scale extractions to large-scale data mining operations.",
  },
  {
    icon: <FaShieldAlt size={60} />,
    title: "Data Security",
    description:
      "Your data is encrypted and processed securely. We adhere to the highest security standards to protect your information.",
    detail:
      "With end-to-end encryption and secure data storage, you can trust our solution to handle sensitive information with care.",
  },
  {
    icon: <FaBolt size={60} />,
    title: "Fast and Efficient",
    description:
      "Extract data at lightning speeds without compromising on quality.",
    detail:
      "Utilize our high-speed scraping engine that optimizes performance for faster results while maintaining high data integrity.",
  },
  {
    icon: <FaGlobe size={60} />,
    title: "Global Reach",
    description:
      "Our scraping solution supports websites from all over the world, handling different languages and formats.",
    detail:
      "Break language barriers and scrape multilingual websites with ease. Our tool automatically detects and processes content in various languages.",
  },
];

const Features = () => {
  return (
    <>
      <div className="min-h-screen bg-white py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-5xl font-extrabold text-center mb-16">
            Powerful Features to Boost Your Business
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            {features.map((feature, index) => (
              <Parallax
                key={index}
                className="w-full p-6 text-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-lg shadow-2xl hover:shadow-xl transform transition duration-500 hover:scale-105"
                y={[20, -20]}
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="text-white mb-4">{feature.icon}</div>
                  <h3 className="text-2xl font-bold mb-2 text-white">
                    {feature.title}
                  </h3>
                  <p className="text-gray-200 mb-4">{feature.description}</p>
                  <p className="text-gray-100">{feature.detail}</p>
                </motion.div>
              </Parallax>
            ))}
          </div>
        </div>
      </div>

      <footer className="py-16 bg-gradient-to-r from-purple-700 via-purple-900 to-indigo-900 text-white">
        <div className="container mx-auto px-6">
          {/* Logo and Branding */}
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <motion.div
              className="flex items-center mb-4 md:mb-0"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="p-3 ">
                <img src="/AIScrape.png" alt="Logo" className="w-20 h-20" />
              </div>
              <h1 className="ml-4 text-3xl font-bold">AI Scraper</h1>
            </motion.div>

            {/* Email Subscription Form */}
            <motion.div
              className="flex items-center bg-white rounded-full p-2 shadow-lg"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded-full text-gray-700 focus:outline-none"
              />
              <button className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white py-2 px-6 rounded-full hover:from-indigo-600 hover:to-purple-600 transition-colors duration-300">
                Subscribe
              </button>
            </motion.div>
          </div>

          {/* Social Media Links */}
          <div className="flex justify-center space-x-6 mt-8">
            {[
              { icon: <FaFacebook size={24} />, link: "#" },
              { icon: <FaTwitter size={24} />, link: "#" },
              { icon: <FaInstagram size={24} />, link: "#" },
              { icon: <FaLinkedin size={24} />, link: "#" },
            ].map((social, index) => (
              <motion.a
                key={index}
                href={social.link}
                className="text-white hover:text-indigo-300 transition-colors duration-300"
                whileHover={{ scale: 1.2 }}
              >
                {social.icon}
              </motion.a>
            ))}
          </div>

          {/* Footer Bottom Content */}
          <div className="mt-12 text-center text-gray-400">
            <p className="text-sm">
              © VulcanTech AI Scraper. All rights reserved. | Designed for
              data-driven innovation.
            </p>
          </div>

          {/* Background Animated Shapes */}
          <motion.div
            className="absolute bottom-0 left-0 w-48 h-48 bg-indigo-500 opacity-30 rounded-full filter blur-3xl"
            animate={{ scale: [1, 1.5, 1], opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 8, repeat: Infinity }}
          />
          <motion.div
            className="absolute bottom-0 right-0 w-64 h-64 bg-purple-500 opacity-20 rounded-full filter blur-3xl"
            animate={{ scale: [1, 1.5, 1], opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 10, repeat: Infinity }}
          />
        </div>
      </footer>
    </>
  );
};

export default Features;
