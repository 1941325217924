// src/pages/Signup.js
import React from "react";

const Signup = () => {
  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-20">
      <div className="w-full max-w-md p-8 space-y-6 bg-gray-100 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-center text-indigo-600">
          Create Account
        </h2>
        <form className="space-y-4">
          <input
            type="text"
            placeholder="Name"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-600"
          />
          <input
            type="email"
            placeholder="Email"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-600"
          />
          <input
            type="password"
            placeholder="Password"
            className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-600"
          />
          <button className="w-full bg-indigo-600 text-white py-3 rounded hover:bg-indigo-700 transition-colors">
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
