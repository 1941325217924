// src/pages/Dashboard.js
import React from "react";

const Dashboard = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center py-10">
      {/* Logo */}
      <div className="mb-8">
        {/* <img src="/path/to/logo.png" alt="Logo" className="w-24 mx-auto" /> */}
      </div>

      {/* Form Container */}
      <div className="w-full max-w-lg p-6 space-y-6 bg-gray-800 rounded-lg shadow-md">
        {/* Input Fields */}
        <input
          type="text"
          placeholder="Enter URL"
          className="w-full p-3 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-400"
        />
        <textarea
          placeholder="Enter your query"
          className="w-full p-3 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          rows={5}
        ></textarea>

        {/* Submit Button */}
        <button className="w-full bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 rounded-full hover:from-purple-600 hover:to-indigo-600 transition-colors">
          Submit
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
