// src/pages/Pricing.js
import React from "react";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";
import {
  FaCog,
  FaIndustry,
  FaLightbulb,
  FaUserShield,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaRobot,
  FaChartLine,
  FaDatabase,
  FaShieldAlt,
  FaBolt,
  FaGlobe,
} from "react-icons/fa";
const pricingPlans = [
  {
    title: "Basic Plan",
    price: "$29/month",
    features: ["Up to 10k pages/month", "Basic Support", "Limited Features"],
    detail:
      "Ideal for individuals and small teams looking to get started with web scraping. Includes essential features to help you begin extracting data.",
  },
  {
    title: "Pro Plan",
    price: "$99/month",
    features: ["Up to 100k pages/month", "Priority Support", "All Features"],
    detail:
      "Perfect for growing businesses that need more power and flexibility. Comes with all the advanced features and priority support for quick assistance.",
  },
  {
    title: "Enterprise Plan",
    price: "Custom Pricing",
    features: ["Unlimited Scraping", "24/7 Support", "Custom Solutions"],
    detail:
      "For large enterprises with complex scraping needs. Includes unlimited access, custom solutions tailored to your business, and round-the-clock support.",
  },
];

const Pricing = () => {
  return (
    <>
      <div className="min-h-screen bg-gray-100 py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-5xl font-extrabold text-center mb-16">
            Choose a Plan That Suits Your Needs
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {pricingPlans.map((plan, index) => (
              <motion.div
                key={index}
                className="p-8 bg-white rounded-lg shadow-lg text-center transform hover:scale-105 transition duration-500"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <h3 className="text-3xl font-bold mb-4 text-indigo-600">
                  {plan.title}
                </h3>
                <p className="text-5xl font-bold mb-6">{plan.price}</p>
                <ul className="mb-6 text-left space-y-2">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="text-gray-700 flex items-center">
                      <FaCheckCircle className="text-green-500 mr-2" />{" "}
                      {feature}
                    </li>
                  ))}
                </ul>
                <p className="text-gray-600 mb-6">{plan.detail}</p>
                <button className="bg-indigo-600 text-white py-3 px-6 rounded-full hover:bg-indigo-700 transition-colors shadow-lg">
                  Choose Plan
                </button>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <footer className="py-16 bg-gradient-to-r from-purple-700 via-purple-900 to-indigo-900 text-white">
        <div className="container mx-auto px-6">
          {/* Logo and Branding */}
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <motion.div
              className="flex items-center mb-4 md:mb-0"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="p-3 ">
                <img src="/AIScrape.png" alt="Logo" className="w-20 h-20" />
              </div>
              <h1 className="ml-4 text-3xl font-bold">AI Scraper</h1>
            </motion.div>

            {/* Email Subscription Form */}
            <motion.div
              className="flex items-center bg-white rounded-full p-2 shadow-lg"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded-full text-gray-700 focus:outline-none"
              />
              <button className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white py-2 px-6 rounded-full hover:from-indigo-600 hover:to-purple-600 transition-colors duration-300">
                Subscribe
              </button>
            </motion.div>
          </div>

          {/* Social Media Links */}
          <div className="flex justify-center space-x-6 mt-8">
            {[
              { icon: <FaFacebook size={24} />, link: "#" },
              { icon: <FaTwitter size={24} />, link: "#" },
              { icon: <FaInstagram size={24} />, link: "#" },
              { icon: <FaLinkedin size={24} />, link: "#" },
            ].map((social, index) => (
              <motion.a
                key={index}
                href={social.link}
                className="text-white hover:text-indigo-300 transition-colors duration-300"
                whileHover={{ scale: 1.2 }}
              >
                {social.icon}
              </motion.a>
            ))}
          </div>

          {/* Footer Bottom Content */}
          <div className="mt-12 text-center text-gray-400">
            <p className="text-sm">
              © VulcanTech AI Scraper. All rights reserved. | Designed for
              data-driven innovation.
            </p>
          </div>

          {/* Background Animated Shapes */}
          <motion.div
            className="absolute bottom-0 left-0 w-48 h-48 bg-indigo-500 opacity-30 rounded-full filter blur-3xl"
            animate={{ scale: [1, 1.5, 1], opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 8, repeat: Infinity }}
          />
          <motion.div
            className="absolute bottom-0 right-0 w-64 h-64 bg-purple-500 opacity-20 rounded-full filter blur-3xl"
            animate={{ scale: [1, 1.5, 1], opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 10, repeat: Infinity }}
          />
        </div>
      </footer>
    </>
  );
};

export default Pricing;
