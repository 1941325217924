// src/components/HeroSection.js
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaLink, FaRobot, FaChartBar, FaShieldAlt } from "react-icons/fa";

const HeroSection = () => {
  return (
    <section className="relative bg-gradient-to-br from-purple-700 to-blue-600 text-white py-32 overflow-hidden">
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
        {/* Left Side Content */}
        <motion.div
          className="w-full md:w-1/2 space-y-6"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.7 }}
        >
          <h1 className="text-6xl font-extrabold leading-tight">
            Extract Data from{" "}
            <span className="text-yellow-300">Any Website</span> with AI-Powered
            Scraping
          </h1>
          <p className="text-xl mb-8">
            Revolutionize your data collection process with our cutting-edge AI
            scraping tool. Extract, analyze, and visualize data from any website
            in real time.
          </p>

          {/* Key Benefits */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <motion.div
              className="flex items-center space-x-4"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <FaRobot className="text-yellow-300 text-3xl" />
              <div>
                <h3 className="text-xl font-bold">AI-Powered Algorithms</h3>
                <p className="text-gray-200">
                  Extract complex and dynamic web data effortlessly.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="flex items-center space-x-4"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <FaChartBar className="text-yellow-300 text-3xl" />
              <div>
                <h3 className="text-xl font-bold">Real-Time Analytics</h3>
                <p className="text-gray-200">
                  Get insights instantly with real-time data processing.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="flex items-center space-x-4"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <FaLink className="text-yellow-300 text-3xl" />
              <div>
                <h3 className="text-xl font-bold">Flexible Integration</h3>
                <p className="text-gray-200">
                  Seamlessly integrate scraped data with your tools.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="flex items-center space-x-4"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <FaShieldAlt className="text-yellow-300 text-3xl" />
              <div>
                <h3 className="text-xl font-bold">Secure and Scalable</h3>
                <p className="text-gray-200">
                  Built with top security standards and scalability in mind.
                </p>
              </div>
            </motion.div>
          </div>

          {/* Call to Actions */}
          <div className="flex space-x-4 mt-8">
            <Link
              to="/signup"
              className="bg-white text-blue-500 py-3 px-8 rounded-full text-lg font-semibold hover:bg-gray-100 transition-colors shadow-lg"
            >
              Get Started
            </Link>
            <Link
              to="/features"
              className="bg-transparent border-2 border-white text-white py-3 px-8 rounded-full text-lg font-semibold hover:bg-white hover:text-blue-500 transition-colors"
            >
              Learn More
            </Link>
          </div>
        </motion.div>

        {/* Right Side Visuals */}
        <motion.div
          className="w-full md:w-1/2 mt-10 md:mt-0 relative"
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.7 }}
        >
          {/* Replace Lottie animation with a simpler visual element */}
          <div className="bg-white rounded-lg shadow-2xl p-8 text-center">
            <h3 className="text-2xl font-bold text-blue-600">
              Transform Your Data
            </h3>
            <p className="text-gray-700 mt-4">
              Our AI scraping tool simplifies data extraction, allowing you to
              focus on insights.
            </p>
            <div className="flex justify-center mt-6">
              <FaChartBar className="text-blue-500 text-4xl mx-4 animate-pulse" />
              <FaLink className="text-blue-500 text-4xl mx-4 animate-pulse" />
              <FaRobot className="text-blue-500 text-4xl mx-4 animate-pulse" />
            </div>
          </div>
        </motion.div>
      </div>

      {/* Background Elements */}
      <motion.div
        className="absolute top-0 left-0 w-96 h-96 bg-blue-500 opacity-20 rounded-full filter blur-2xl"
        animate={{ scale: [1, 1.5, 1], opacity: [0.3, 0.6, 0.3] }}
        transition={{ duration: 10, repeat: Infinity }}
      />
      <motion.div
        className="absolute bottom-0 right-0 w-72 h-72 bg-purple-500 opacity-20 rounded-full filter blur-2xl"
        animate={{ scale: [1, 1.5, 1], opacity: [0.3, 0.6, 0.3] }}
        transition={{ duration: 12, repeat: Infinity }}
      />
    </section>
  );
};

export default HeroSection;
