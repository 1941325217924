// src/components/Header.js
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="fixed w-full bg-white shadow-lg z-50">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <h1 className="text-3xl font-bold text-indigo-600">
            Vulcantech Scraper
          </h1>
        </Link>
        <nav className="hidden md:flex items-center space-x-6">
          <Link
            to="/features"
            className="text-gray-600 hover:text-indigo-600 cursor-pointer"
          >
            Features
          </Link>
          <Link
            to="/pricing"
            className="text-gray-600 hover:text-indigo-600 cursor-pointer"
          >
            Pricing
          </Link>
          <Link
            to="/login"
            className="bg-indigo-600 text-white py-2 px-4 rounded-full hover:bg-indigo-700 transition-colors"
          >
            Login
          </Link>
          <Link
            to="/signup"
            className="bg-white border-2 border-indigo-600 text-indigo-600 py-2 px-4 rounded-full hover:bg-indigo-600 hover:text-white transition-colors"
          >
            Create Account
          </Link>
        </nav>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-600">
            {isOpen ? (
              <XIcon className="h-6 w-6" />
            ) : (
              <MenuIcon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <motion.nav
          className="bg-white shadow-md md:hidden flex flex-col items-center p-6 space-y-4"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
        >
          <Link
            to="/features"
            className="text-gray-600 hover:text-indigo-600 cursor-pointer"
            onClick={toggleMenu}
          >
            Features
          </Link>
          <Link
            to="/pricing"
            className="text-gray-600 hover:text-indigo-600 cursor-pointer"
            onClick={toggleMenu}
          >
            Pricing
          </Link>
          <Link
            to="/login"
            className="bg-indigo-600 text-white py-2 px-4 rounded-full hover:bg-indigo-700 transition-colors"
            onClick={toggleMenu}
          >
            Login
          </Link>
          <Link
            to="/signup"
            className="bg-white border-2 border-indigo-600 text-indigo-600 py-2 px-4 rounded-full hover:bg-indigo-600 hover:text-white transition-colors"
            onClick={toggleMenu}
          >
            Create Account
          </Link>
        </motion.nav>
      )}
    </header>
  );
};

export default Header;
