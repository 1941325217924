// src/components/HomePage.js
import React from "react";
import { motion } from "framer-motion";
import HeroSection from "../components/HeroSection"; // Import your existing HeroSection
import {
  FaCog,
  FaIndustry,
  FaLightbulb,
  FaUserShield,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaRobot,
  FaChartLine,
  FaDatabase,
  FaShieldAlt,
  FaBolt,
  FaGlobe,
} from "react-icons/fa";

const HomePage = () => {
  return (
    <div>
      {/* Hero Section */}
      <HeroSection />

      {/* How It Works Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-6 text-center">
          <motion.h2
            className="text-4xl font-extrabold text-gray-800 mb-8"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            How Our AI Scraper Works
          </motion.h2>
          <div className="flex flex-wrap justify-center gap-12">
            {[
              {
                icon: <FaCog className="text-blue-500 text-5xl mx-auto mb-4" />,
                title: "Step 1",
                description:
                  "Enter the URL of the website you want to scrape. Our AI does the rest!",
              },
              {
                icon: (
                  <FaIndustry className="text-blue-500 text-5xl mx-auto mb-4" />
                ),
                title: "Step 2",
                description:
                  "The AI analyzes the page structure and identifies the data you need.",
              },
              {
                icon: (
                  <FaLightbulb className="text-blue-500 text-5xl mx-auto mb-4" />
                ),
                title: "Step 3",
                description:
                  "Scraping begins and data is extracted in a structured format.",
              },
              {
                icon: (
                  <FaUserShield className="text-blue-500 text-5xl mx-auto mb-4" />
                ),
                title: "Step 4",
                description:
                  "Export your data in your preferred format, securely and efficiently.",
              },
            ].map((step, index) => (
              <motion.div
                key={index}
                className="w-full md:w-1/4 bg-white rounded-lg shadow-lg p-6 transform hover:-translate-y-3 hover:shadow-2xl transition-all duration-500"
                whileHover={{ scale: 1.05, rotate: [0, 5, -5, 0] }}
                transition={{ duration: 0.3 }}
              >
                {step.icon}
                <h3 className="text-2xl font-bold text-gray-700 mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-20 bg-gradient-to-br from-purple-500 to-indigo-500 text-white">
        <div className="container mx-auto px-6 text-center">
          <motion.h2
            className="text-4xl font-extrabold mb-8"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Use Cases for Every Industry
          </motion.h2>
          <motion.p
            className="text-xl mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            From e-commerce to finance, our AI scraper is designed to fit every
            industry.
          </motion.p>
          <div className="flex flex-wrap justify-center gap-12">
            {[
              {
                title: "E-Commerce",
                description:
                  "Track competitor prices, product availability, and customer reviews in real-time.",
              },
              {
                title: "Finance",
                description:
                  "Monitor market trends, stock prices, and financial news to make informed decisions.",
              },
              {
                title: "Real Estate",
                description:
                  "Gather data on property listings, market trends, and pricing insights for smarter investments.",
              },
            ].map((useCase, index) => (
              <motion.div
                key={index}
                className="w-full md:w-1/3 bg-white text-gray-800 rounded-lg shadow-lg p-6 hover:bg-indigo-100 hover:text-indigo-600 transition-all duration-500"
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                }}
                transition={{ duration: 0.3 }}
              >
                <h3 className="text-2xl font-bold mb-4">{useCase.title}</h3>
                <p>{useCase.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-6 text-center">
          <motion.h2
            className="text-4xl font-extrabold text-black-800 mb-8"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Why Choose Our AI Scraper?
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-black">
            {[
              {
                title: "Fast and Efficient",
                description:
                  "Extract large volumes of data in seconds without compromising quality.",
              },
              {
                title: "User-Friendly Interface",
                description:
                  "Our intuitive platform allows anyone to scrape data without technical skills.",
              },
              {
                title: "Secure and Reliable",
                description:
                  "Protect your data with state-of-the-art encryption and secure cloud storage.",
              },
            ].map((benefit, index) => (
              <motion.div
                key={index}
                className="relative bg-gradient-to-br from-white via-gray-50 to-gray-200 rounded-lg shadow-lg p-6 transition-all duration-500 transform hover:shadow-2xl hover:bg-gradient-to-r hover:from-indigo-500 hover:to-purple-500 hover:text-white"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                {/* Border effect */}
                <div className="absolute inset-0 border-2 border-transparent rounded-lg hover:border-indigo-500 transition-all duration-500"></div>

                {/* Content */}
                <h3 className="text-2xl font-bold mb-4 text-gray-800 transition-colors duration-500 group-hover:text-white">
                  {benefit.title}
                </h3>
                <p className="text-gray-700 transition-colors duration-500 group-hover:text-white">
                  {benefit.description}
                </p>

                {/* Background Glow Effect */}
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-300 to-purple-300 opacity-0 rounded-lg transition-opacity duration-500 hover:opacity-20"></div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Customer Support Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6 text-center">
          <motion.div
            className="w-full md:w-2/3 mx-auto bg-gray-100 rounded-lg shadow-lg p-8 hover:bg-blue-100 transition-all duration-500"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-2xl font-bold text-blue-600 mb-4">
              Get Assistance Anytime
            </h3>
            <p className="text-gray-700">
              Our support team is available 24/7 via live chat, email, or phone
              to answer your questions.
            </p>
          </motion.div>
        </div>
      </section>
      {/* Footer Section */}
      <footer className="py-16 bg-gradient-to-r from-purple-700 via-purple-900 to-indigo-900 text-white">
        <div className="container mx-auto px-6">
          {/* Logo and Branding */}
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <motion.div
              className="flex items-center mb-4 md:mb-0"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="p-3 ">
                <img src="/AIScrape.png" alt="Logo" className="w-20 h-20" />
              </div>
              <h1 className="ml-4 text-3xl font-bold">AI Scraper</h1>
            </motion.div>

            {/* Email Subscription Form */}
            <motion.div
              className="flex items-center bg-white rounded-full p-2 shadow-lg"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded-full text-gray-700 focus:outline-none"
              />
              <button className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white py-2 px-6 rounded-full hover:from-indigo-600 hover:to-purple-600 transition-colors duration-300">
                Subscribe
              </button>
            </motion.div>
          </div>

          {/* Social Media Links */}
          <div className="flex justify-center space-x-6 mt-8">
            {[
              { icon: <FaFacebook size={24} />, link: "#" },
              { icon: <FaTwitter size={24} />, link: "#" },
              { icon: <FaInstagram size={24} />, link: "#" },
              { icon: <FaLinkedin size={24} />, link: "#" },
            ].map((social, index) => (
              <motion.a
                key={index}
                href={social.link}
                className="text-white hover:text-indigo-300 transition-colors duration-300"
                whileHover={{ scale: 1.2 }}
              >
                {social.icon}
              </motion.a>
            ))}
          </div>

          {/* Footer Bottom Content */}
          <div className="mt-12 text-center text-gray-400">
            <p className="text-sm">
              © VulcanTech AI Scraper. All rights reserved. | Designed for
              data-driven innovation.
            </p>
          </div>

          {/* Background Animated Shapes */}
          <motion.div
            className="absolute bottom-0 left-0 w-48 h-48 bg-indigo-500 opacity-30 rounded-full filter blur-3xl"
            animate={{ scale: [1, 1.5, 1], opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 8, repeat: Infinity }}
          />
          <motion.div
            className="absolute bottom-0 right-0 w-64 h-64 bg-purple-500 opacity-20 rounded-full filter blur-3xl"
            animate={{ scale: [1, 1.5, 1], opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 10, repeat: Infinity }}
          />
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
